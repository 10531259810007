.header {
  padding: 2.222vw 0;
  top: -100px;
  transition: top 0.5s ease-out;
}

.header.scrolled {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(11, 11, 45, 0.59);
  backdrop-filter: blur(0.90278vw);
  z-index: 100;
  padding: 1vw 0;
  transition: top 0.5s ease-out;
}

.mobile-menu-inner-header {
  display: none;
}

.logo-col {
  width: 15.97222vw;
  flex-basis: 15.97222vw;
}

.logo {
  display: flex;
  width: 17.014vw;
  height: 100%;
  align-items: center;
}

.logo img {
  width: 100%;
  height: auto;
}

.nav-col {
  width: 68.3vw;
  flex-basis: 68.3vw;
}

.desktop-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 62vw;
  margin-left: auto;
}

.mobile-menu-button,
.mobile-menu-close-button,
.mobile-menu-bottom-logo {
  display: none;
}

.nav-links {
  display: flex;
}

.nav-links a {
  display: block;
  margin-left: 2.222vw;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 1.11111vw;
  font-family: "Inter", sans-serif;
  text-transform: none;
  font-weight: 600;
  line-height: 160%;
  color: #ffe9ee;
  transition: color 0.5s ease-out;
}

.nav-links a:hover {
  color: #FFA3C8;
  transition: color 0.5s ease-out;
}

.nav-links a:active,
.nav-links a:focus,
.nav-links a.active {
  color: #FF7BB0;
  transition: color 0.5s ease-out;
}

.nav-socials li a,
.nav-socials li button {
  appearance: none;
  border: 0;
  outline: 0;
  background-color: #FFE9EE;
  height: 3.542vw;
  width: 3.542vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.278vw;
  position: relative;
}

.nav-socials li a svg,
.nav-socials li button svg {
  width: 1.736vw;
  height: auto;
}

.nav-socials li a,
.nav-socials li button,
.nav-socials li a svg,
.nav-socials li button svg,
.nav-socials li svg path {
  transition: all 0.5s ease-out;
}

.nav-socials li .header-mintBtn {
  background-color: #ff92be;
  color: #04017d;
  border-radius: 0.278vw;
  font-size: 1.25vw;
  width: 6.597vw;
  height: 3.542vw;
  transition: all 0.5s ease-out;
}

.nav-socials li a:hover,
.nav-socials li button:hover {
  background-color: #FFA3C8;
  color: #04017d;
  transition: all 0.5s ease-out;
}

.nav-socials li a:active,
.nav-socials li a:focus,
.nav-socials li button:active,
.nav-socials li button:focus {
  background-color: #FF7BB0;
  color: #04017d;
  transition: all 0.5s ease-out;
}

.social-icon-status {
  position: absolute;
  width: 0.83333vw;
  height: 0.83333vw;
  border-radius: 0.83333vw;
  display: none;
  background: #000;
  bottom: -0.20833vw;
  right: -0.20833vw;
}

.status-online {
  background: #10d172;
}

.status-offline {
  background: #ff6073;
}

.nav-links a:first-of-type {
  margin-left: 0;
}

.nav-socials {
  display: flex;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-socials li {
  margin-left: 1.389vw;
  list-style: none;
}

.nav-socials li:first-of-type {
  margin-left: 0;
}

.header .container {
  width: 95.556vw;
}

.nav-socials .hover-primary,
.nav-socials .hover-primary:hover {
  background: transparent;
}

.shkaryButton {
  position: relative;
  z-index: 1;
}

.intro {
  position: relative;
  padding-top: 6.042vw;
}

.intro-text-area h1 {
  font-size: 6.25vw;
  line-height: 1;
  margin: 0 0 2.9165vw;
  max-width: 55vw;
  font-weight: normal;
}

.intro-text-area {
  width: 100%;
  flex-basis: 100%;
}

.intro-side-image {
  width: 0;
  flex-basis: 0;
}

.intro-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro-text-area p {
  font-size: 1.111vw;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 4%;
  margin: 0 0 2.9165vw;
}

.intro-buttons {
  display: flex;
  justify-content: space-between;
  width: 32.639vw;
  flex-basis: 32.639vw;
}

.shkaryButton button,
.shkaryButton a {
  appearance: none;
  width: 15.278vw;
  background: #ff92be;
  border: 0.069vw solid #04017d;
  outline: none;
  padding: 1.528vw 0;
  border-radius: 0.278vw;
  font-family: "Inter", sans-serif;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 100%;
  color: #04017d;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-out;
}

.intro-meet-button a,
.intro-meet-button button {
  background: #ffe9ee;
}

.shkaryButton button:hover,
.shkaryButton a:hover {
  background-color: #ffa3c8;
  transition: all 0.5s ease-out;
}

.shkaryButton button:active,
.shkaryButton a:active,
.shkaryButton button:focus,
.shkaryButton a:focus {
  background-color: #ff7bb0;
  transition: all 0.5s ease-out;
}

.intro-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.intro-spaceship {
  width: 46.528vw;
  margin-bottom: -1.25vw;
}

.intro-spaceship img {
  width: 100%;
  height: auto;
}

.lore {
  padding-top: 7.5vw;
  position: relative;
}

.lore-title-area h2 {
  font-size: 4.861vw;
  line-height: 107%;
  margin: 0 0 2.778vw;
  max-width: 31.181vw;
  font-weight: normal;
}

.text-line-br {
  display: block;
}

.lore-title-area {
  width: 31.181vw;
  flex-basis: 31.181vw;
}

.lore-text-area {
  width: 41.667vw;
  flex-basis: 41.667vw;
}

.lore-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lore-text-area p {
  font-size: 1.111vw;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 4%;
  margin: 0 0 2.778vw;
}

.lore-buttons {
  display: flex;
  justify-content: space-between;
  width: 32.639vw;
  flex-basis: 32.639vw;
}

.lore-bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.lore-benefits {
  list-style: none;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 36.778vw;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.lore-benefits li {
  width: 50%;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  margin: 0 0 2.778vw 0;
  font-size: 1.3vw;
  line-height: 2;
  color: #ffe9ee;
  /* -webkit-text-stroke-width: 0.035vw;
  -webkit-text-stroke-color: #04017D; */
}

.lore-benefits li img {
  display: block;
  width: 2.778vw;
  height: auto;
  margin-right: 1.389vw;
  user-select: none;
  pointer-events: none;
}

.loreScenes {
  display: flex;
  width: 100%;
  /* margin-bottom: -6.944vw; */
}

.loreScenes-item {
  width: 50%;
  flex-basis: 50%;
}

.loreScenes-item img {
  display: block;
  width: 100%;
  height: auto;
}

.loreScenes-shark {
  margin-top: -7.778vw;
}

.loreScenes-octo {
  padding-top: 12.431vw;
}

.utility {
  position: relative;
  padding-top: 7vw;
}

.utility h2 {
  text-align: center;
  margin-bottom: 3.472vw;
}

.utility-wrapper {
  width: 57.639vw;
  margin: 0 auto;
  background: rgba(255, 233, 238, 0.8);
  border-radius: 0.278vw;
  padding: 2.778vw 2.778vw 0 2.778vw;
  position: relative;
  border: 0.069vw solid #04017d;
  backdrop-filter: blur(0.556vw);
  box-sizing: border-box;
}

.utility-items {
  height: 36.667vw;
  overflow-y: scroll;
  scrollbar-color: #ffd55a rgba(255, 233, 238, 0.8);
  scrollbar-width: thin;
}

.utility-items::-webkit-scrollbar {
  width: 0.694vw;
}

.utility-items::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: inset 0 0 0.694vw transparent;
  border-radius: 3.472vw;
}

.utility-items::-webkit-scrollbar-thumb {
  background: #ff92be;
  border-radius: 3.472vw;
  border: 0.069vw solid #04017d;
}

.utility-items::-webkit-scrollbar-thumb:hover {
  background: #ffa3c8;
}

.utility-item {
  width: 47.847vw;
  display: flex;
  justify-content: space-between;
}

.utility-icon {
  width: 6.111vw;
  flex-basis: 6.111vw;
  height: auto;
}

.utility-icon img {
  width: 100%;
  height: auto;
  display: block;
}

.utility-content {
  width: 39.931vw;
  flex-basis: 39.931vw;
}

.utility-content h3 {
  font-size: 1.806vw;
  /* -webkit-text-stroke-color: rgba(4, 1, 125, 1);
  -webkit-text-stroke-width: 0.069vw; */
  line-height: 154%;
  margin-bottom: 0.833vw;
  margin-top: 0;
  cursor: pointer;
  transition: color 0.5s ease-out;
}

.utility-content h3:hover {
  color: #FF92BE;
  transition: color 0.5s ease-out;
}

.utility-content p {
  font-family: "Inter", sans-serif;
  font-size: 1.111vw;
  font-weight: 500;
  color: #04017d;
  line-height: 175%;
  letter-spacing: 4%;
  margin-bottom: 1.944vw;
}

.utility-buttons {
  margin-top: 2.778vw;
  text-align: center;
}

.utility-buttons button,
.utility-buttons a {
  text-align: center;
  margin: 0 auto;
}

.utilityScenes {
  margin-bottom: -6.944vw;
}

.utilityScenes-item {
  width: 20.833vw;
  margin-top: -2vw;
}

.utilityWrapperScenes {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  user-select: none;
  pointer-events: none;
}

.utilityWScenesTop {
  width: 21.181vw;
  position: absolute;
  top: 0;
  left: 0;
}

.utilityWrapperScenes img {
  width: 100%;
  height: auto;
  display: block;
}

.utilityWScenesBottom {
  width: 23.958vw;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

.artwork {
  position: relative;
  padding-top: 7vw;
  /* margin-bottom: -6.944vw; */
}

.artwork-title {
  margin-bottom: 3.472vw;
}

.artworkDisplay {
  width: 100%;
}

.artworkDisplay img {
  display: block;
  width: 100%;
  height: auto;
}

.artworkDisplay img:last-of-type {
  display: none;
}

.artworkExWrapper {
  position: relative;
}

.artworkNav {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.778vw;
  box-sizing: border-box;
}

.artworkNav div,
.artworkNav button {
  display: flex;
  background: #ffe9ee;
  color: #04017d;
  border: 0.069vw solid #04017d;
  box-shadow: 0 0.208vw 0 #04017d;
  border-radius: 0.278vw;
  width: 2.778vw;
  height: 2.778vw;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.arrowBtn.arrow--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.artworkPagination {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  left: 0;
  margin-top: 2.778vw;
  display: none;
}

.artworkPagination .dots {
  display: flex;
  justify-content: center;
}

.artworkPagination .dot {
  background: rgba(255, 233, 238, 0.8);
  border: 0.069vw solid #04017D;
  backdrop-filter: blur(1.111vw);
  border-radius: 1.111vw;
  width: 1.111vw;
  height: 1.111vw;
  display: block;
  padding: 0;
  margin: 0 0.556vw;
  box-sizing: border-box;
}

.artworkPagination .dot.active {
  background: #FF92BE;
}

.artworkScenes-one {
  width: 30.208vw;
  margin-top: -7.361vw;
}

.artworkScenes-two {
  width: 21.181vw;
  margin-top: -10vw;
}

.artworkScenes {
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 1;
  /* margin-bottom: -2.778vw; */
}

.artworkScenes-item img {
  display: block;
  width: 100%;
  height: auto;
}

.artworkNav div svg {
  width: 1.111vw;
  height: auto;
  display: block;
}

.launchpad {
  position: relative;
  padding-top: 7.986vw;
}

.launchpad-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.launchpad-text-area {
  width: 38.611vw;
  flex-basis: 38.611vw;
  margin-right: -1.944vw;
}

.launchpad-text-area p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.111vw;
  line-height: 175%;
  letter-spacing: 4%;
  color: #ffe9ee;
  margin: 0 0 2.778vw;
  max-width: 30.625vw;
}

.launchpad-image-area {
  width: 44.444vw;
  flex-basis: 44.444vw;
}

.launchpad-dragon {
  display: block;
  margin-top: -6.25vw;
}

.launchpad-dragon img {
  display: block;
  width: 100%;
  height: auto;
}

.launchpadScenes {
  position: relative;
  width: 100%;
  text-align: center;
  /* margin-bottom: -1.264vw; */
}

.launchpadScenes-eyes {
  margin: -12.014vw auto auto auto;
  width: 27.778vw;
}

.artist {
  position: relative;
  padding-top: 8vw;
}

.artist .container {
  width: 90.625vw;
  margin-left: 0;
}

.artist-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.artist-image-area {
  width: 55.903vw;
  flex-basis: 55.903vw;
}

.artist-image-area img {
  display: block;
  width: 100%;
  height: auto;
}

.artist-text-area {
  width: 31.944vw;
  flex-basis: 31.944vw;
}

.artist-text-area h3 {
  font-family: "Regards", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.806vw;
  line-height: 154%;
  color: #ff92be;
  margin: 5.764vw 0 1.111vw 0;
}

.artist-text-area h2 {
  margin-bottom: 2.222vw;
}

.artist-text-area p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.111vw;
  line-height: 175%;
  letter-spacing: 4%;
  color: #ffe9ee;
  margin-bottom: 2.778vw;
}

.artistScenes {
  position: relative;
}

.artistScenes-crown {
  width: 30.069vw;
  margin: 0 auto 0 27.917vw;
}

.artistScenes-crown img {
  display: block;
  width: 100%;
  height: auto;
}

.shkarythings {
  position: relative;
  padding-top: 8vw;
}

.shkarythings-title {
  margin-bottom: 3.472vw;
}

.shkarythings-items {
  display: flex;
  justify-content: space-between;
}

.shkarythings-item {
  width: 25.694vw;
  flex-basis: 25.694vw;
}

.shkarythings-item h3 {
  font-family: 'Regards', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.806vw;
  line-height: 154%;
  text-align: center;
  color: #FFE9EE;
  margin: 0 0 0.556vw;
  text-transform: uppercase;
}

.shkarythings p {
  text-align: center;
}

.shkarythings .shkaryButton button,
.shkarythings .shkaryButton a {
  margin: 0 auto;
}

.shkarythings-item-image {
  margin: 3.264vw auto 0;
  width: 20.833vw;
  position: relative;
}

.shkarythings-item-image img {
  display: block;
  width: 100%;
  height: auto;
}

.shkarythings-casino-image {
  width: 19.792vw;
}

.shkarythings-casino-image img {
  transform: scale(2);
  margin: 7vw auto;
}

.footer {
  position: relative;
  padding: 2vw 0 4vw;
}

.footer-logo {
  width: 17.014vw;
  margin: 5vw auto 2.778vw;
}

.footer-logo img {
  display: block;
  width: 100%;
  height: auto;
}

.footer h2 {
  font-family: "Regards", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.806vw;
  line-height: 154%;
  text-align: center;
  color: #ffe9ee;
  margin: 0 0 2.778vw;
}

.footer .nav-socials {
  text-align: center;
  justify-content: center;
}

a.disabled-btn,
button.disabled-btn,
.nav-socials li a.disabled-btn,
.nav-socials li button.disabled-btn {
  background-color: #ff92be;
  border-color: #ce6791;
  color: #ce6791;
  cursor: not-allowed;
}

a.disabled-btn svg,
button.disabled-btn,
a.disabled-btn svg path,
button.disabled-btn {
  fill: #ce6791;
}

a.disabled-btn:hover,
button.disabled-btn:hover,
a.disabled-btn:active,
button.disabled-btn:active,
a.disabled-btn:focus,
button.disabled-btn:focus {
  background-color: #ff92be;
  border-color: #ce6791;
  color: #ce6791;
}
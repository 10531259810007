@font-face {
  font-family: "Regards";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  font-stretch: 100%;
  src: url("./fonts/Regards.woff") format("woff"),
    url("./fonts/Regards.ttf") format("truetype");
}
html {
  scroll-behavior: smooth;
  scrollbar-color: #FF92BE #04017d;
  scrollbar-width: thin;
  overflow-x: hidden;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Regards", sans-serif;
  font-size: 0.79vw;
  font-weight: normal;
  line-height: 0.98958333333333vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4e0fa7;
  background-image: url("./assets/img/bg-desktop.png");
  background-repeat: repeat;
  background-size: 100% auto;
  background-position: top center;
  color: #fff;
  overflow-x: hidden;
}
p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.111vw;
  line-height: 175%;
  letter-spacing: 4%;
  color: #ffe9ee;
  margin: 0 auto 1.667vw;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Regards", sans-serif;
  font-weight: normal;
  /* -webkit-text-stroke-color: rgba(4, 1, 125, 1);
  -webkit-text-stroke-width: 0.069vw; */
}
h1 {
  font-size: 6.25vw;
  line-height: 1;
  margin: 0 0 4.444vw;
  font-weight: normal;
}
h2 {
  font-size: 4.861vw;
  line-height: 107%;
  margin: 0 0 2.778vw;
  font-weight: normal;
}
a,
input[type="submit"],
button {
  cursor: pointer;
  text-decoration: none;
  font-family: "Regards", sans-serif;
  font-weight: normal;
  /* -webkit-text-stroke-color: rgba(4, 1, 125, 1);
  -webkit-text-stroke-width: 0.035vw; */
}
a:link,
a:visited {
  color: inherit;
  text-decoration: inherit;
}
img {
  max-width: 100%;
  height: auto;
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #FF92BE;
}
::-webkit-scrollbar-track {
  background: #0b0b2d;
}

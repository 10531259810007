.App {
  position: relative;
  overflow-x: hidden;
}
.container {
  width: 81.250vw;
  margin: 0 auto;
}
.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.col {
  width: 100%;
}
.textleft,
.text-left {
  text-align: left;
}
.textcenter,
.text-center {
  text-align: center;
}
.textright,
.text-right {
  text-align: right;
}
.newLine {
  display: block;
}
main {
  position: relative;
}
.hover-primary,
.hover-primary-stroke,
.hover-secondary,
.hover-secondary-stroke {
  transition: all 0.5s ease-out;
}
.hover-primary:hover,
.hover-primary-stroke:hover {
  background-color: #FF92BE;
  color: #04017D;
  transition: all 0.5s ease-out;
}
.hover-secondary:hover,
.hover-secondary-stroke:hover,
.hover-secondary-stroke.close:hover {
  color: #04017D;
  background-color: #FFE9EE;
  transition: all 0.5s ease-out;
}
.hover-primary:hover svg,
.hover-primary:hover svg path {
  color: #FF92BE;
  fill: #FF92BE;
  transition: all 0.5s ease-out;
}
.hover-primary-stroke:hover svg,
.hover-primary-stroke:hover svg path {
  color: #FF92BE;
  stroke: #FF92BE;
  transition: all 0.5s ease-out;
}
.hover-secondary:hover svg,
.hover-secondary:hover svg path {
  color: #FFE9EE;
  fill: #FFE9EE;
  transition: all 0.5s ease-out;
}
.hover-secondary-stroke:hover svg,
.hover-secondary-stroke:hover svg path {
  color: #FFE9EE;
  stroke: #FFE9EE;
  transition: all 0.5s ease-out;
}
.active-tertiary:active,
.active-tertiary:focus,
.active-tertiary.active {
  background-color: #FF7BB0;
  color: #04017D;
}
.active-tertiary:focus svg,
.active-tertiary:focus svg path,
.active-tertiary:focus {
  color: #fff;
  fill: #fff;
}
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 2s ease-out, transform 2s ease-out,
    visibility 2s ease-out;
  will-change: opacity, transform, visibility;
  user-select: none;
  pointer-events: none;
}
.fade-in-top {
  transform: translateY(-20vh);
}
.fade-in-bottom {
  transform: translateY(20vh);
}
.fade-in-left {
  transform: translateX(-20vh);
}
.fade-in-right {
  transform: translateX(20vh);
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.text-primary {
  color: #FF92BE;
}
::selection {
  background-color: #FF92BE;
  color: #04017D;
}
.d-m-none {
  display: block;
}
.d-d-none {
  display: none;
}
.displayhidden {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
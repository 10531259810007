@media (max-width: 600px) {

  html,
  body {
    overflow-x: hidden;
    overflow-y: initial;
  }

  body {
    background-image: url('./assets/img/bg-mobile.png');
    font-size: 4.375vw;
    line-height: 171%;
    letter-spacing: 0.4%;
    scrollbar-width: none;
  }

  body::-webkit-scrollbar {
    width: 0;
  }

  p,
  .lore-text-area p {
    font-size: 4.375vw;
    line-height: 171%;
    letter-spacing: 0.4%;
    margin: 0 0 7.500vw;
  }

  .App {
    overflow-x: hidden;
  }

  body.lock-scroll {
    background-color: #FF92BE;
  }

  .d-m-none {
    display: none;
  }

  .d-d-none {
    display: block;
  }

  .container,
  .header .container {
    width: 87.500vw;
  }

  .header {
    padding: 6.250vw 0;
  }

  .header.scrolled {
    padding: 3.625vw 0;
  }

  .logo-col {
    width: 46.875vw;
    flex-basis: 46.875vw;
  }

  .logo {
    width: 100%;
  }

  .mobile-menu-button button {
    appearance: none;
    border: 0;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 12.500vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #FF92BE;
    border: 0.313vw solid #04017D;
    border-radius: 1.250vw;
    box-sizing: border-box;
  }

  .mobile-menu-button button svg {
    display: block;
    width: 7.188vw;
    height: auto;
  }

  .nav-col,
  .mobile-menu-inner-header .mobile-menu-button {
    width: 17.188vw;
    flex-basis: 17.188vw;
  }

  .mobile-menu-button,
  .mobile-menu-close-button {
    display: block;
  }

  .mobile-menu-full-screen {
    position: fixed;
    width: 100vw;
    height: 0;
    background: #FF92BE;
    top: 0;
    left: 0;
    z-index: 10;
    display: block;
    flex-wrap: wrap;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out, height 0s ease-out 0.5s, padding 0s ease-out 0.5s;
  }

  .mobile-menu-visible {
    display: block;
    height: 100vh;
    padding: 6.250vw;
    opacity: 1;
    transition: opacity 0.5s ease-out, height 0s ease-out 0ms, padding 0s ease-out 0s;
  }

  .mobile-menu-full-screen::-webkit-scrollbar {
    width: 0;
  }

  .mobile-menu-inner-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20vw;
  }

  .mobile-menu-inner-logo {
    width: 46.875vw;
  }

  .mobile-menu-inner-logo img {
    display: block;
    width: 100%;
    height: auto;
  }

  .mobile-menu-close-button {
    text-align: center;
    flex-basis: 17.188vw;
    width: 17.188vw;
    order: 1;
  }

  .mobile-menu-close-button button {
    width: 100%;
    height: 12.500vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    border-radius: 2.1875vw;
    appearance: none;
    border: 0;
    outline: none;
    background-color: #29195a;

  }

  .mobile-menu-close-button button svg path {
    stroke: #6759ef;
  }

  .mobile-menu-close-button button svg {
    width: 5.88125vw;
    height: auto;
  }

  .nav-links {
    display: block;
    flex-basis: 100%;
    width: 100%;
    order: 2;
  }

  .nav-links a {
    font-style: normal;
    font-weight: 600;
    font-size: 6.250vw;
    line-height: 140%;
    text-align: center;
    color: #04017D;
    margin: 7.500vw auto 0;
  }

  .nav-links a:first-of-type {
    margin-top: 0;
  }

  .header-connect-wallet {
    order: 3;
    flex-basis: 100%;
    width: 100%;
    margin-top: 11.875vw;
  }

  .connect-wallet-button {
    text-align: center;
  }

  .connect-wallet-button button {
    width: 71.875vw;
    height: 13.125vw;
    margin: 0 auto;
    border-radius: 6.25vw;
    font-size: 5.625vw;
    line-height: 160%;
  }

  .connect-wallet-button button svg {
    width: 5vw;
    height: auto;
    margin-right: 1.5625vw;
  }

  .nav-socials {
    flex-basis: 100%;
    width: 100%;
    order: 4;
    margin-top: 25vw;
    /* justify-content: space-between; */
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nav-socials li {
    margin: 0 3vw;
    display: block;
    text-align: center;
    order: 3;
  }

  .nav-socials li:first-of-type {
    order: 1;
  }

  .nav-socials li:last-of-type {
    order: 2;
  }

  .nav-socials li a,
  .nav-socials li button {
    width: 15.938vw;
    height: 12.5vw;
    border-radius: 1.25vw;
    margin: 0 auto;
    border: 0.313vw solid #04017D;
    background-color: #FF92BE;
  }

  .nav-socials li a svg,
  .nav-socials li button svg {
    width: 5.625vw;
    height: auto;
  }

  .nav-socials li .header-mintBtn {
    width: 43.125vw;
    height: 12.500vw;
    border-radius: 1.250vw;
    color: #FF92BE;
    background: #04017D;
    font-size: 5vw;
  }

  .social-icon-status {
    width: 5vw;
    height: 5vw;
    border-radius: 5vw;
    bottom: -1vw;
    right: -1vw;
    border: 0.625vw solid #160535;
  }

  .mobile-menu-bottom-logo {
    display: block;
    flex-basis: 100%;
    width: 100%;
    order: 5;
    margin-top: 15.3125vw;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .mobile-menu-bottom-logo img {
    display: block;
    width: 100%;
    height: auto;
  }

  .mobile-menu-bottom-logo svg {
    width: 40vw;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .mobile-menu-bottom-logo svg path {
    fill: #29195a;
  }

  .intro-wrapper {
    flex-wrap: wrap;
  }

  .intro {
    padding-top: 20vw;
  }

  .intro-side-image {
    order: 1;
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 6.25vw;
  }

  .intro-text-area {
    order: 2;
    width: 100%;
    flex-basis: 100%;
  }

  .intro-side-image img {
    width: 63.75vw;
    margin: 0 auto;
    display: block;
    height: auto;
  }

  .intro-text-area h1 {
    font-size: 15.625vw;
    line-height: 110%;
    text-align: center;
    max-width: 100%;
    margin: 0 0 7.500vw;
  }

  .intro-text-area p {
    font-size: 6.250vw;
    text-align: center;
    margin: 0 0 5vw;
  }

  .intro-bottom {
    display: block;
  }

  .intro-buttons {
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 10.500vw;
  }

  .intro-buttons .shkaryButton {
    margin-bottom: 7.500vw;
  }

  .shkaryButton button,
  .shkaryButton a {
    width: 100%;
    height: 18.750vw;
    border-radius: 1.250vw;
    margin: 0 auto;
    font-size: 5vw;
  }

  .intro-spaceship {
    width: 135.938vw;
    height: auto;
    display: block;
  }

  .intro-spaceship {
    width: 135.938vw;
    height: auto;
    display: block;
  }

  .lore {
    padding-top: 28.5vw;
  }

  .lore-wrapper {
    display: block;
  }

  .lore-title-area,
  .lore-text-area {
    display: block;
    width: 100%;
  }

  .lore-title-area h2,
  h2 {
    font-size: 12.500vw;
    line-height: 112%;
    width: 100%;
    max-width: 100%;
    margin-bottom: 7.500vw;
  }

  .lore-benefits {
    display: block;
    width: 100%;
  }

  .lore-benefits li {
    width: 100%;
    font-size: 5vw;
    -webkit-text-stroke-width: 0.156vw;
    -webkit-text-stroke-color: #04017D;
    margin: 0 0 7.500vw;
  }

  .lore-benefits li img {
    width: 12.500vw;
    margin-right: 6.250vw;
  }

  .lore-bottom.d-d-none {
    margin-bottom: 10.625vw;
  }

  .lore-buttons,
  .launchpad-buttons,
  .artist-buttons,
  .shkarythings .shkaryButton button {
    display: block;
    width: 64.375vw;
    flex-basis: 64.375vw;
  }

  .loreScenes-item.loreScenes-shark {
    margin-top: 0;
    width: 100vw;
    transform: translateX(-8vw);
  }

  .loreScenes {
    display: block;
  }

  .loreScenes-item.loreScenes-octo {
    padding-top: 0;
    width: 123.750vw;
    margin-top: -5vw;
  }

  .loreScenes {
    margin-bottom: 0;
  }

  .utility {
    padding-top: 10.313vw;
  }

  .utility .container {
    width: 100%;
  }

  .utility h2 {
    margin-bottom: 7.500vw;
    font-size: 12.500vw;
    line-height: 112%;
  }

  .utility-wrapper {
    background: transparent;
    border: 0;
    padding: 0 0 0;
    width: 100%;
    backdrop-filter: none;
  }

  .utility-items {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    touch-action: pan-y;
    -webkit-user-select: none;
    user-select: none;
    -khtml-user-select: none;
    width: 100%;
    height: auto;
  }

  .utility-item {
    background: rgba(255, 233, 238, 0.8);
    border: 0.313vw solid #04017D;
    backdrop-filter: blur(2.500vw);
    border-radius: 1.250vw;
    padding: 10vw;
    height: auto;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .utility-icon {
    width: 20vw;
    margin-bottom: 5vw;
  }

  .utility-content h3 {
    font-size: 6.250vw;
    margin: 0 0 1.875vw;
    line-height: 140%;
    -webkit-text-stroke-width: 0.250vw;
  }

  .utility-content {
    width: 100%;
  }

  .utility-content p {
    font-size: 4.375vw;
    line-height: 171%;
    margin-bottom: 0;
  }

  .utility-content p:not(:last-of-type) {
    margin-bottom: 5vw;
  }

  .utility-buttons {
    margin-top: 8.125vw;
    margin-bottom: 12.813vw;
    max-width: 64.375vw;
    margin-left: auto;
    margin-right: auto;
  }

  .utilityScenes {
    margin-bottom: 0;
  }

  .utilityScenes-item {
    margin-top: 0;
    width: 54.375vw;
  }

  .artwork {
    padding-top: 8vw;
  }

  .artwork .container {
    width: 100%;
    margin-left: auto;
    margin-right: 0;
  }

  .artwork-title {
    margin-bottom: 7.500vw;
  }

  .artworkNav {
    display: none;
  }

  .artworkExWrapper {
    width: 100%;
  }

  .artworkDisplay img:first-of-type {
    display: none;
  }

  .artworkDisplay img:last-of-type {
    display: block;
  }

  .artworkPagination {
    display: block;
  }

  .artworkPagination {
    margin-top: 6.250vw;
  }

  .artworkPagination .dot {
    border-width: 0.313vw;
    backdrop-filter: blur(2.500vw);
    border-radius: 2.500vw;
    width: 2.500vw;
    height: 2.500vw;
    margin: 0 1.875vw;
  }

  .artworkScenes {
    margin-bottom: 0;
    display: block;
  }

  .artworkScenes-one {
    margin-top: 0;
    width: 107.500vw;
    transform: translateX(-23.438vw);
  }

  .artworkScenes-two {
    display: none;
  }

  .launchpad {
    padding-top: 10.500vw;
  }

  .launchpad-wrapper {
    display: block;
  }

  .launchpad-text-area {
    width: 100%;
    margin-right: 0;
  }

  .launchpad-text-area p {
    font-size: 4.375vw;
    max-width: 100%;
    margin-bottom: 7.500vw;
  }

  .launchpad-image-area {
    width: 105.938vw;
  }

  .launchpad-dragon {
    margin-top: 0;
    transform: translateX(-5vw);
  }

  .launchpadScenes {
    margin-bottom: 0;
  }

  .launchpadScenes-eyes {
    width: 64.063vw;
    margin: 0 auto 0 6.250vw;
  }

  .artist {
    padding-top: 10.500vw;
  }

  .launchpadScenes {
    margin-top: -20vw;
  }

  .artist-wrapper {
    display: flex;
    flex-direction: column;
  }

  .artist-image-area {
    display: block;
    width: 100%;
    order: 2;
  }

  .artist-text-area {
    width: 100%;
    order: 1;
  }

  .artist .container {
    width: 87.500vw;
    margin: 0 auto;
  }

  .artist-text-area h3 {
    margin-top: 0;
    margin-bottom: 5vw;
    font-size: 6.250vw;
  }

  .artist-text-area h2 {
    font-size: 12.500vw;
    line-height: 112%;
    margin-top: 0;
    margin-bottom: 6.250vw;
  }

  .artist-text-area p {
    font-size: 4.375vw;
    line-height: 171%;
    margin: 0 0 7.500vw;
  }

  .artist-bottom {
    margin-bottom: 10vw;
  }

  .artist-fernando {
    transform: scale(1.3) translateX(5vw) translateY(10vw);
  }

  .artistScenes {
    margin-top: 5vw;
  }

  .artistScenes-crown {
    width: 70.625vw;
    margin: 16vw 0 0;
  }

  .shkarythings {
    padding-top: 22.500vw;
  }

  .shkarythings-items {
    display: block;
    margin-top: 7.500vw;
  }

  .shkarythings-item {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .shkarythings-item-bottom {
    order: 1;
  }

  .shkarythings-item h3 {
    font-size: 6.250vw;
    margin: 0 0 3.750vw;
    order: 2;
  }

  .shkarythings p {
    order: 3;
  }

  .shkarythings-item-buttons {
    order: 4;
  }

  .shkarythings-item-image {
    width: 100%;
    margin: 0 auto;
  }

  .shkarythings-item {
    margin-bottom: 10.500vw;
  }

  .shkarythings-casino-image {
    width: 100%;
    transform: scale(1.2);
  }

  .shkarythings-casino-image img {
    transform: none;
  }

  .footer {
    padding: 10vw 0 5vw;
  }

  .footer-logo {
    width: 46.875vw;
    margin: 0 auto 7.500vw;
  }

  .footer h2 {
    font-size: 6.250vw;
    line-height: 140%;
    margin: 0 auto 7.500vw;
    max-width: 74.063vw;
  }

  .footer .nav-socials {
    margin: 0 auto;
    max-width: 74.063vw;
    /* justify-content: space-between; */
  }

  .footer .nav-socials li:last-of-type {
    order: 5;
  }

  .footer .nav-socials li a,
  .footer .nav-socials li button {
    background-color: #FFE9EE;
  }

  .footer .nav-socials li .header-mintBtn {
    width: 29.688vw;
    background-color: #FF92BE;
    color: #04017D;
  }
}
@media (min-width: 601px) {
  #utility .utility-item {
    transform: none;
  }
}